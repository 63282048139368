<template>
  <div>
    <div class="w-100 d-flex justify-content-end">
      <b-button variant="primary" class="mb-1" @click="formModalActive = true">
        <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
      </b-button>
    </div>
    <b-card>
      <b-table :fields="fields" :items="items">
        <!-- <template #cell(action)>
          <div class="d-flex">
            <div>
              <b-button variant="outline-warning" size="sm">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template> -->
      </b-table>
    </b-card>

    <b-pagination
      v-model="pagination.page"
      :total-rows="total"
      :per-page="pagination.pageSize"
      @input="fetchBranches"
    ></b-pagination>

    <b-modal hide-footer v-model="formModalActive" no-close-on-backdrop>
      <PaymentForm @closeModal="formModalActive = false;"/>
    </b-modal>
  </div>
</template>

<script>
import PaymentForm from '@/views/directories/payment-method/components/PaymentForm'

export default {
  name: 'Index',
  components: { PaymentForm },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('message.ID')
        },
        {
          key: 'name',
          label: this.$t('message.name_title')
        },
        // {
        //   key: 'action',
        //   label: 'Action'
        // },
      ],
      items: [
        {
          id: 1,
          name: 'Name1'
        },
        {
          id: 2,
          name: 'Name2'
        },
      ],
      formModalActive: false,
      pagination: {
        page: 1,
        pageSize: 20
      },
      total: 1
    }
  },
}
</script>
